const comItem = [{ path: '', name: '管理后台' }]
const menuObj = [
  {
    path: '/',
    name: '用户管理',
    meta: { title: '用户管理', permission: ['admin'] },
    icon: 'user',
    breadCrumb: [...comItem, { path: '', name: '用户管理' }],
    children: [
      {
        path: '/user/info',
        name: '用户详情',
        meta: { title: '用户管理', permission: ['admin'] },
        icon: 'ordered-list',
        breadCrumb: [...comItem, { path: '/', name: '用户管理' }, { path: '', name: '用户详情' }]
      }
    ]
  },
  {
    path: '/order',
    name: '订单管理',
    meta: { title: '订单管理', permission: ['admin'] },
    icon: 'ordered-list',
    breadCrumb: [...comItem, { path: '', name: '订单管理' }],
    children:[
      {
        path: '/order/add',
        name: '订单管理',
        meta: { title: '订单管理', permission: ['admin'] },
        icon: 'ordered-list',
        breadCrumb: [...comItem, { path: '/', name: '订单管理' }, { path: '', name: '添加订单' }]
      },
      {
        path: '/order/info',
        name: '订单管理',
        meta: { title: '订单管理', permission: ['admin'] },
        icon: 'ordered-list',
        breadCrumb: [...comItem, { path: '/', name: '订单管理' }, { path: '', name: '订单详情' }]
      }
    ]
  }
]
export default menuObj
