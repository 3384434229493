import React from "react";
import { Select } from "antd";
const Option = Select.Option;
const util = {
  getUrlParams(url, name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); //定义正则表达式
    var r = url.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  // getParams(url, name) {
  //   var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); //定义正则表达式
  //   var r = url.match(reg);
  //   if (r != null) return unescape(r[2]);
  //   return null;
  // },
  getOptionList(data) {
    let options = []; //[<Option value="0" key="all_key">全部</Option>];
    if (!data) {
      return [];
    }

    data.forEach(item => {
      options.push(
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      );
    });
    return options;
  },
  mapMenu(arr) {
    console.log(arr);
    let ary1 = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].children.length > 0) {
        for (let j = 0; j < arr[i].children.length; j++) {
          ary1.push(arr[i].children[j]);
        }
      }
    }
    return arr.concat(ary1);
  },
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  returnStatus(status) {
    let str = "";
    switch (status) {
      case 1:
        str = "初始化";
        break;
      case 2:
        str = "处理中";
        break;
      case 3:
        str = "已支付";
        break;
      case 4:
        str = "已过期";
        break;
      case 5:
        str = "已取消";
        break;
      case 6:
        str = "已完成";
        break;
      default:
        break;
    }
    return str;
  }
};
export default util;
