import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;

//UI组件
const MyFooter = props => {
  return (
    <Footer
      style={{
        textAlign: "center",
        background: "#f0f2f5",
        height: 0
      }}
    >
      ©2020 .com All Rights Reserved
    </Footer>
  );
};
export default MyFooter;
