import React, { Component } from "react";
import { Menu, Icon, Layout, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import logo from "@static/imgs/logo.png";
import myMenu from "@/router/list";
import $ajax from "@utils/ajax";
import apilist from "@api/list";
import info from "@config/datainfo";
import utils from "@utils";

const { SubMenu } = Menu;
// window.apilist=apilist
const { Sider } = Layout;
class MySider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: [myMenu[0].path],
      title: "管理后台",
      collapsed: false,
      menuList: myMenu,
      selected: "",
      originMenu: myMenu
    };
  }
  onCollapse = collapsed => {
    // console.log(collapsed)
    this.setState({ collapsed });
  };
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };
  toJump = item => {
    this.setState({ selectedKeys: [item.path] });
    this.props.history.push(item.path);
  };
  // static getDerivedStateFromProps(nextProps, prevState){
  //   console.log(nextProps, prevState);
  // }
  componentDidMount() {
    const { pathname } = this.props.location;
    console.log(pathname);
    if (pathname) {
      this.setState({ selectedKeys: [pathname] });
    }
  }

  componentWillUnmount() {}
  render() {
    let { collapsed, menuList, selectedKeys } = this.state;

    return (
      <div
        id="mymenu"
        style={{
          width: 250,
          background: "#fff",
          position: "relative",
          zIndex: 2
        }}
      >
        {/*       
        <Button className="" type="primary" onClick={this.toggleCollapsed} style={{ marginBottom: 16 }}>
          <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
        </Button> */}
        <Menu
          selectedKeys={selectedKeys}
          // defaultOpenKeys={['sub1']}
          mode="inline"
          theme="light"
          inlineCollapsed={this.state.collapsed}
        >
          {menuList.map((item, index) => {
            if (item.level === 1) {
              return (
                <Menu.Item
                  onClick={() => {
                    this.toJump(item);
                  }}
                  key={item.path}
                >
                  {/* <Icon type="pie-chart" /> */}
                  <span className={`icontp icontp${index}`}></span>
                  <span>{item.title}</span>
                </Menu.Item>
              );
            } else {
              return null;
            }
          })}
          {/* <Menu.Item key="1">
            <Icon type="pie-chart" />
            <span>Option 1</span>
          </Menu.Item>
          <Menu.Item key="2">
            <Icon type="desktop" />
            <span>Option 2</span>
          </Menu.Item>
          <Menu.Item key="3">
            <Icon type="inbox" />
            <span>Option 3</span>
          </Menu.Item> */}
          {/* <SubMenu
            key="sub1"
            title={
              <span>
                <Icon type="mail" />
                <span>Navigation One</span>
              </span>
            }
          >
            <Menu.Item key="5">Option 5</Menu.Item>
            <Menu.Item key="6">Option 6</Menu.Item>
            <Menu.Item key="7">Option 7</Menu.Item>
            <Menu.Item key="8">Option 8</Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub2"
            title={
              <span>
                <Icon type="appstore" />
                <span>Navigation Two</span>
              </span>
            }
          >
            <Menu.Item key="9">Option 9</Menu.Item>
            <Menu.Item key="10">Option 10</Menu.Item>
            <SubMenu key="sub3" title="Submenu">
              <Menu.Item key="11">Option 11</Menu.Item>
              <Menu.Item key="12">Option 12</Menu.Item>
            </SubMenu>
          </SubMenu> */}
        </Menu>
      </div>
    );
  }
}

export default withRouter(MySider);
