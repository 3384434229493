
//  const data = {
//   token: '',
//   login: ''
// }
//  const data = {
//   token: 'A0FD3B6889724D23A43EFFE0C1406683',
//   login: 'lct'
// }
let baseURL = null
let downLoadUrl = null
let urlValue = window.location.href
if (urlValue.includes('ypshengxian')) {
  baseURL = 'https://apigw.ypshengxian.com'
} else {
  if(urlValue.includes('10007')){
    baseURL = 'https://apigw-dev.ypshengxian.com'
  }else if(urlValue.includes('10005')){
    baseURL = 'https://apigw-test.ypshengxian.com'
  }else if(urlValue.includes('20005')){
    baseURL = 'https://apigw-pre.ypshengxian.com'
  }else {
    baseURL = 'http://172.16.4.178:28080'
  }
  
}
downLoadUrl = baseURL
const data = {
  token: '666ACFCFE9054B1CB7472EE5FCB42F0E',
  login: 'lct2019',
  downLoadUrl,
  baseURL
}
export default data