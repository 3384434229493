import React, { PureComponent } from 'react'
import { Breadcrumb } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import menuList from '@config/menu'
import $utils from '@utils'
let menus = $utils.mapMenu(menuList)
class MyBreadCrumb extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { locationBread: [] }
  }
  render() {
    let { locationBread } = this.state
    let { breadCrumb } = locationBread
    // console.log(breadCrumb)
    return (
      <div className="myBreadcrumb">
        < Breadcrumb style={{ margin: '16px' }}>
          {breadCrumb.map((item) => {
          // return { item.path ? <Link key={item.name} to={item.path}>{item.name}</Link> : <Breadcrumb.Item>User</Breadcrumb.Item> }
          return <Breadcrumb.Item key={item.name}>{item.path?<Link key={item.name} to={item.path}>{item.name}</Link>:<span>{item.name}</span>}</Breadcrumb.Item>
          })}
        </Breadcrumb >
      </div >

    )
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps)
    // console.log(menuList)
    let pathname = nextProps.location.pathname
    let ret = menus.find((el) => {
      return el.path === pathname
    })
    return { locationBread: ret }
  }
  componentDidUpdate() {

    // console.log('componentDidUpdate')
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    // console.log(prevProps, prevState)
    return null
  }
  componentDidMount() {
    // console.log(this.props.history)
  }

  componentWillUnmount() { }
}

export default withRouter(MyBreadCrumb)
