//menu 需要配置，用在面包屑导航那里
import ApplicationForm from "@views/application-form/loadable"; //
import Zhuanrang from "@views/zhuanrang/loadable"; //
import Chanpinliebiao from "@views/chanpinliebiao/loadable"; //
import Zhuceliebiao from "@views/zhuceliebiao/loadable"; //
import Fabugonggao from "@views/fabugonggao/loadable"; //
import FabugonggaoDetail from "@views/fabugonggao/detail/add/loadable"; //
import Yuyueliebiao from "@views/yuyueliebiao/loadable"; //
import AgentDetail from "@views/agent/detail/info/loadable"; //代理商详情
import Fabu from "@views/fabu/loadable"; //banner
import Customer from "@views/customer/loadable"; //用户管理
import CustomerDetail from "@views/customer/detail/info/loadable"; //用户管理详情

import Product from "@views/product/loadable"; //用户管理
import ExposureStage from "@views/exposure-stage/loadable"; //曝光台
import ApplicationFormDetail from "@views/application-form/detail/info/loadable"; //投保单

const routerList = [{
    id: "1",
    title: "产品发布",
    level: 1,
    path: "/fanbu",
    redirect: false,
    component: Fabu
  },
  // {id:'2',title:'曝光台管理',level:1, path: '/exposurestage', redirect: false, component: ExposureStage },
  {
    id: "3",
    title: "转让列表",
    level: 1,
    path: "/zhuanrang",
    redirect: false,
    component: Zhuanrang
  },
  {
    id: "4",
    title: "产品列表",
    level: 1,
    path: "/chanpinliebiao",
    redirect: false,
    component: Chanpinliebiao
  },
  {
    id: "5",
    title: "注册列表",
    level: 1,
    path: "/zhuceliebiao",
    redirect: false,
    component: Zhuceliebiao
  },
  {
    id: "6",
    title: "发布公告",
    level: 1,
    path: "/fabugonggao",
    redirect: false,
    component: Fabugonggao
  },
  {
    id: "7",
    title: "预约列表",
    level: 2,
    path: "/yuyueliebiao",
    redirect: false,
    component: Yuyueliebiao
  }, {
    id: "8",
    title: "发布公告",
    level: 2,
    path: "/fabugonggaodetail",
    redirect: false,
    component: FabugonggaoDetail
  },
  // {
  //   id: "8",
  //   title: "代理商管理",
  //   level: 2,
  //   path: "/agent",
  //   redirect: false,
  //   component: Agent
  // },
  // {
  //   id: "9",
  //   title: "保单详情",
  //   level: 2,
  //   path: "/applicationform/detail",
  //   redirect: false,
  //   component: ApplicationFormDetail
  // },
  // {
  //   id: "10",
  //   title: "代理商详情",
  //   level: 2,
  //   path: "/agent/detail",
  //   redirect: false,
  //   component: AgentDetail
  // },
  // {
  //   id: "11",
  //   title: "理赔详情",
  //   level: 2,
  //   path: "/settlement/detail",
  //   redirect: false,
  //   component: SettlementDetail
  // },
  // {
  //   id: "12",
  //   title: "曝光台",
  //   level: 2,
  //   path: "/exposurestage",
  //   redirect: false,
  //   component: ExposureStage
  // },
  // {
  //   id: "13",
  //   title: "用户管理详情",
  //   level: 2,
  //   path: "/customer/detail",
  //   redirect: false,
  //   component: CustomerDetail
  // }
];
export default routerList;