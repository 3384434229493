import React from "react";
import { connect } from "react-redux";
import MySider from "@views/layout/sider";
import MyHeader from "@views/layout/header";
import MyFooter from "@views/layout/footer";
import MyBreadCrumb from "@views/layout/breadcrumb";
import { Layout, ConfigProvider } from "antd";
import { withRouter } from "react-router-dom";
import events from "@utils/events";
import zhCN from "antd/lib/locale-provider/zh_CN";
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // console.log('componentDidMount11')
    events.addListener("errorLogn", message => {
      console.log("message", message);
      this.props.history.push("/login");
    });
  }
  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Layout
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <MyHeader />
          <Layout
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              background: "#F4F5FA"
            }}
          >
            <MySider />
            {/* <MyBreadCrumb /> */}
            <div
              className="contentChild"
              style={{
                flex: 1,
                height: "100%",
                boxSizing: "border-box",
                padding: 40
              }}
            >
              {this.props.children}
            </div>
          </Layout>
          {/* <MyFooter /> */}
        </Layout>
      </ConfigProvider>
    );
  }
}
export default withRouter(Admin);
