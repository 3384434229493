/*
router.js 控制全部的路由初始化设置
*/

import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'; //Redirect
import App from '../App';
import Admin from '../admin';
import Login from '@views/login/loadable';
import Xiaochengxu from '@views/xiaochengxu/loadable';
import list from './list';
console.log(list);
export default class ERouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { list };
  }
  render() {
    let { list } = this.state;
    console.log(localStorage.getItem('token'));
    return (
      <HashRouter>
        <App>
          <Switch>
            <Route path="/xiaochengxu" component={Xiaochengxu} />
            <Route path="/login" component={Login} />
            <Route
              path="/"
              render={() => (
                <Admin>
                  <Switch>
                    {list.map((item) => {
                      return (
                        <Route
                          exact
                          path={item.path}
                          component={item.component}
                          key={item.id}
                        />
                      );
                    })}
                    {localStorage.getItem('token') ? (
                      <Redirect exact from="/*" to="/fanbu" />
                    ) : (
                      <Redirect exact from="/*" to="/login" />
                    )}
                  </Switch>
                </Admin>
              )}
            />
          </Switch>
        </App>
      </HashRouter>
    );
  }
}
