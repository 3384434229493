import React, { Component } from "react";
import { actionCreators as commonActionCreators } from "@store/common/";
import { connect } from "react-redux";
import { Layout, message } from "antd";
import { Select } from "antd";
import apilist from "@api/list";
import $ajax from "@utils/ajax";
import info from "@config/datainfo";
import utils from "@utils";
import { withRouter } from "react-router-dom";
const { Header } = Layout;
const Option = Select.Option;
class MyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cityList: []
    };
  }

  getCityList = async () => {
    // let url = apilist.user
    // let dataVal = { login: utils.getCookie('login') || info.login }
    // let cityVal = {
    //   cityShopId: '',
    //   code: '',
    //   name: ''
    // }
    // try {
    //   let { res } = await $ajax.post(url, dataVal)
    //   // res = [{ code: "C500100", name: "重庆市", cityShopId: 990 }, { code: "C340100", name: "合肥市", cityShopId: 846 }]
    //   if (res.length > 0) {
    //     //分配了城市
    //     if (JSON.parse(sessionStorage.getItem('cityInfo')) && JSON.parse(sessionStorage.getItem('cityInfo')).code) {
    //       cityVal = JSON.parse(sessionStorage.getItem('cityInfo'))
    //     } else {
    //       cityVal = res[0]
    //     }
    //     this.setSessionCity(cityVal)
    //     this.props.setCityFn(cityVal)
    //     this.setState({
    //       cityList: res
    //     })
    //   } else {
    //     message.error('你还没有分配城市，无权访问');
    //     setTimeout(() => {
    //       window.location.href = '/#/403'
    //     }, 1000);
    //   }
    // } catch (error) {
    //   // console.log(error)
    // }
  };
  loginOut = () => {
    localStorage.setItem("token", null);
    this.props.history.push("/login");
  };
  componentDidMount() {}
  componentWillUnmount() {}
  render() {
    let { cityList } = this.state;
    let { cityInfo } = this.props;
    return (
      <Header
        style={{
          background: "#fff",
          padding: 0,
          display: "flex",
          flexDirection: "row",
          height: 100
        }}
      >
        <div className="le">
          <div className="dd01"></div>
        </div>
        <div className="ri">
          <div className="ttx01">
            <div className="lm01"></div>
          </div>
          <div className="mzmz01">Admin</div>
          <div className="yb01" onClick={this.loginOut}>
            <div className="tbt012"></div>
            <div className="tbt011">退出</div>
          </div>
        </div>
      </Header>
    );
  }
}

export default withRouter(MyHeader);
