const apilist = {
	policy: '/aliyun/oss/policy',
	users: '/users',
	notices: '/notices',
	delNotice: '/delNotice',
	books: '/books',
	export: '/book/export',
	sms: '/book/sms',
	noticesDel: '/admin/notice',
	addNotices: '/notice',
	products: '/products',
	delete: '/book/delete',
	upload: '/admin/product/upload',
	product: '/product', //发布/修改产品 post
	productUpdate: '/productUpdate', //发布/修改产品 post
	sale: '/product/sale', //'isSale' : true/false
	login: '/login',

	// /product/listInPage
};
export default apilist;
/**
 * 
 * policy: "/aliyun/oss/policy", //GET /aliyun/oss/policy
  login: "/admin/login", //POST /sso/login
  // banner/listInPage
  banner: {
    listInPage: "/banner/listInPage", //get
    addBanner: "/banner/addBanner",
    updateBanner: "/banner/updateBanner",
    del: "/banner/del"
  },
  product: {
    listInPage: "/product/listInPage", //get
    addProduct: "/product/addProduct", //post  /product/addProduct
    editBuyAdvise: "/product/editBuyAdvise", //post  /product/editBuyAdvise  修改保额购买建议(徐振伟)
    showProduct: "/product/showProduct", ///product/showProduct 上架/下架 保额/属性
    delProduct: "/product/delProduct", //POST /product/delProduct删除保额/保额属性
    addProductAttribute: "/product/addProductAttribute", //POST /product/addProductAttribute添加保额属性(徐振伟
    editAttribute: "/product/editAttribute" //POST /product/editAttribute  修改保额属性
  },
  insure: {
    listInPage: "/insure/listInPage", //GET /insure/listInPage
    info: "/insure/info", //GET /insure/info 获取投保单详情
    userInfo: "/insure/userInfo", //GET /insure/userInfo  获取投保单用户详情
    enclosure: "/insure/enclosure", //GET /insure/enclosure 获取投保单附件
    // enclosure:'/insure/enclosure',//post  /insure/enclosure  上传协议
    export: "/insure/export" //GET /insure/export
  },
  agent: {
    listInPage: "/agent/listInPage", //GET 代理商list
    add: "/agent/add", //post  /agent/add  添加代理商(徐振伟)
    edit: "/agent/edit", ///agent/edit  编辑代理商(徐振伟)
    enable: "/agent/enable", ///agent/enable
    export: "/agent/export", // GET /agent/export  代理商列表导出(徐振伟)
    info: "/agent/info" //代理商详情
  },
  claim: {
    listInPage: "/claim/listInPage", //GET
    add: "/agent/add", //post  /agent/add
    edit: "/agent/edit", ///agent/edit
    enable: "/claim/enable", //// post claim/enable
    export: "/claim/export", // GET /agent/export
    claimInfo: "/claim/claimInfo" //详情/claim/claimInfo
  },
  exposure: {
    listInPage: "/exposure/listInPage", //GET /exposure/listInPage 获取曝光台列表(徐振伟)
    add: "/exposure/AddExposure", //POST
    edit: "/exposure/editExposure", //POST /exposure/editExposure
    del: "/exposure/delExposure", //POST
    importExcel: "/exposure/importExcel" //POST
  },
  user: {
    addUser: "/user/addUser", //POST /user/addUser
    editUser: "/user/editUser", //POST /user/editUser
    export: "/user/export", ///GET /user/export
    listInPage: "/user/listInPage" //GET /user/listInPage
  },
  offline: {
    addOffline: "/offline/addOffline", // POST /offline/addOffline 添加营业网点(徐振伟)
    delOffline: "/offline/delOffline", //POST /offline/delOffline
    editOffline: "/offline/editOffline", //POST /offline/editOffline
    listInPage: "/offline/listInPage" //GET /offline/listInPage
  }

   * // banner: '/home/banner',//GET /home/banner
 
    // GET /insure/product/productList
    insure: {
      myProduct: '/insure/product/myProduct', // GET /insure/product/myProduct
      productList: '/insure/product/productList',
      myProductInfo: '/insure/product/myProductInfo', //GET /insure/product/myProductInfo
      productInfo: '/insure/product/productInfo'// GET /insure/product/myProductInfo
      //GET /insure/product/productInfo
    },
    order: {
      pay: '/order/product/pay',//POST /order/product/pay
      extsign: '/order/contract/extsign',// 客户签名 POST /order/contract/extsign
      generate: '/order/contract/generate',// 生成服务协议 POST /order/contract/generate
      thirdPay: '/order/third/pay'//POST /order/third/pay
    },
    userInfo: '/sso/info',// GET /sso/info
    wxLogin: '/user/wxLogin', // post 获取微信授权登陆url
    productCate: '/productCate/tops',
    productList: '/product/list',
    customs: '/product/customs',//获取优选案例商品列表
    productDetail: '/product/detail',
    productAttrs: '/product/attrs',
    addrs: '/user/addrs',//get
    modAddr: '/user/modAddr',
    files: '/order/files',
    orderDetail: '/order/detail',
    create: '/order/create',
    addDetail: '/user/detail',
    del: '/user/delAddr',
    pay: '/order/pay',
  
    orderList: '/order/list',
    addressAdd: '/user/addAddr'//post
   */
