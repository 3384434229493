import * as constants from './constants';
import { fromJS } from 'immutable';

const defaultState = fromJS({
  slideFlag: 1,
  cityInfo: {
    cityShopId: '',
    code: 'C500100',
    name: ''
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SLIDE_FLAG:
      return state.set('slideFlag', action.flag);
    case constants.SET_CITY:
      return state.set('cityInfo', action.cityInfo);
    default:
      return state;
  }
}