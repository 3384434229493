
const apilist = {
  user:{
    list:'/admin/user/accounts',//get
    detail:'/admin/user/detail/',//{id} get
    add:'/admin/user/info',//post
    update:'/admin/user/detail/',//{id} PUT 
    addresses:'/admin/user/addresses'
  },
  order:{
    list:'/admin/orders',
    detail:'/admin/order/detail/',//{orderId}
    update:'/admin/order/detail/'//{orderId} PUT
  },
  wxLogin:'/user/wxLogin' // post 获取微信授权登陆url
}
export default apilist