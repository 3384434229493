import axios from "axios";
import { message } from "antd";
import apis from "@api/";
import events from "@utils/events";
const qs = require("qs");

var axiosObejct = axios.create({
  timeout: 10000,
  baseURL: "/admin",
  responseType: "json",
  withCredentials: false,
  validateStatus: function(status) {
    return status >= 100 && status < 600; // default
  }
});

axiosObejct.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token");

    let Authorization = "";
    if (token) {
      Authorization = token;
    }
    config.headers = Object.assign(config.headers ? config.headers : {}, {
      token
    });
    // console.log(config)
    return config;
  },
  err => {
    console.log(err);
  }
);
// axios.interceptors.request.use(config => {
//   config.headers = Object.assign(config.headers ? config.headers : {}, {
//     Authorization: Authorization
//   })
//   // console.log(config)
//   // if(config.type == 'formData' || config.method != 'post'){
//   //   console.log(11,config)
//   //   return config
//   // }
//   // config.data = qs.stringify(config.data)

//   return config
// }, (err) =>{
//   console.log(err)
//   // return Promise.reject(err);
// })
axiosObejct.interceptors.response.use(
  response => {
    // 对响应数据做些什么
    // console.log("response data", response);
    // console.log('response status',response.status)

    if (response.status === 401) {
      localStorage.setItem("token", "");
      events.emit("errorLogn", response.data);
    }
    return response;
  },
  err => {
    // 对响应错误做些什么
    console.log("err", err.response); // 修改后
    return Promise.resolve(err.response); // 可在组件内获取到服务器返回信息
  }
);
const formatParams = function(params) {
  params = params || {};
  let result = {};
  for (let key in params) {
    const objType = Object.prototype.toString.call(params[key]).slice(8, -1);
    if (objType === "Object" || objType === "Array") {
      result[key] = JSON.stringify(params[key]);
    } else {
      result[key] = params[key];
    }
  }
  return result;
};
export const ajax = {
  get: function(url, params, options) {
    options = options || {};
    let formatResult = formatParams(params);
    formatResult._r_ = Math.floor(Math.random() * 10000000 + 10000000); // 增加一个随机数参数，禁止IE的缓存
    options.params = formatResult;
    // console.log(options)
    return new Promise((resolve, reject) => {
      axiosObejct
        .get(url, options)
        .then(res => {
          // console.log('res.status',res)
          // console.log('res.data',res.data)

          if (res.status === 200) {
            resolve({ success: true, data: { ...res.data } });
          } else {
            if (res.status === 401) {
            } else {
              let { data } = res;
              resolve({ success: false, msg: data.msg || data.message });
              if (data.msg || data.message) {
                message.error(data.msg || data.message);
              } else {
                message.error("服务异常");
              }
            }
          }
        })
        .catch(reject);
    });
  },
  post: function(url, params) {
    // 需要对复杂对象进行JSON序列化
    let options = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };
    let formatResult = formatParams(params);
    formatResult = qs.stringify(formatResult);
    return new Promise((resolve, reject) => {
      axiosObejct
        .post(url, formatResult, options)
        .then(res => {
          if (res) {
            resolve(res);
          }
        })
        .catch(reject);
    });
  }
};
const ajaxSync = {
  async get(url, params, options) {
    let err = null;
    let res = null;
    try {
      res = await ajax.get(url, params, options);
    } catch (error) {
      err = error;
    }
    return {
      res,
      err
    };
  },

  async post(url, params, method = "post") {
    let err = null;
    let res = null;
    try {
      res = await new Promise((resolve, reject) => {
        axiosObejct({
          method: method,
          url: url,
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          },
          data: JSON.stringify(params)
          // data: params
        }).then(res => {
          let { status } = res;
          let data = res.data;
          console.log(">>>>>>", status, data);
          if (status === 200) {
            let result = {};
            result = { success: true, data: { ...data } };

            resolve(result);
          } else {
            message.error(data.msg);
            resolve({ success: false });
          }
        });
      });
    } catch (error) {}
    return {
      res,
      err
    };
  }
};
// window.$ajax = ajaxSync
export default ajaxSync;
