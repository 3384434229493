import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store'
import Router from './router'
import '@static/css/normalize.css'
import '@views/layout/layout.less'
ReactDOM.render(
  <Provider store={store}>
      <Router />
  </Provider>,
  document.getElementById('root')
);
